var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(_vm.component,_vm._b({tag:"component",attrs:{"suggestedProductsRef":_vm.suggestedProductsRef},on:{"update:suggestedProductsRef":function($event){_vm.suggestedProductsRef=$event},"update:suggested-products-ref":function($event){_vm.suggestedProductsRef=$event}}},'component',{
    payload: _vm.payload,
    closable: _vm.closable,
    closeCb: _vm.closeCb,
    isLoading: _vm.isLoading,
    suggestedProducts: _vm.suggestedProducts,
    dynamicContentPayload: _vm.dynamicContentData
  },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }