import { AsyncComponent } from 'vue'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const ADDED_TO_CART_MODAL_COMPONENT_KEY = 'AddedToCartModalComponent'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum AddedToCartModalLayout {
  Basic = 'basic',
  Rich = 'rich'
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface AddedToCartModalConfig {
  layout: string
  loadSuggestedProductsByDelivery: boolean
  shouldSendDynamicContentEvents: boolean
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const ADDED_TO_CART_MODAL_COMPONENT_CONFIG_MAP: AddedToCartModalConfig = {
  layout: AddedToCartModalLayout.Basic,
  loadSuggestedProductsByDelivery: false,
  shouldSendDynamicContentEvents: false
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const addedToCartModalComponentRegistry: Record<string, AsyncComponent> = {
  [AddedToCartModalLayout.Basic]: () => import(/* webpackChunkName: "cart/AddedToCartModalBasic" */ './variants/Basic.vue'),
  [AddedToCartModalLayout.Rich]: () => import(/* webpackChunkName: "cart/AddedToCartModalRich" */ './variants/Rich.vue')
}
