















import { Component, Mixins, Watch } from 'vue-property-decorator'
import { AsyncComponent, VueConstructor } from 'vue'

import { StructureConfigurable } from '../../../../support/mixins'

import { AbstractModal } from '../../../shared/organisms/AbstractModal'
import { DynamicHelperMixin } from '../../../shared/mixins/dynamicHelper.mixin'
import { Loader } from '../../../shared/molecules/Loader'
import { RecommendationAction } from '../../../products/contracts/repositories'
import { SuggestedProductsMixin } from '../../../shared/mixins/suggestedProducts.mixin'
import {
  SuggestedProductsSource
} from '../../../shared/contracts/suggestedProducts'

import {
  ADDED_TO_CART_MODAL_COMPONENT_CONFIG_MAP,
  ADDED_TO_CART_MODAL_COMPONENT_KEY, addedToCartModalComponentRegistry,
  AddedToCartModalConfig
} from './AddedToCartModal.config'
import { AddedToCartModalPayload } from './AddedToCartModal.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<AddedToCartModal>({
  name: 'AddedToCartModal',
  components: {
    Loader
  },
  created () {
    this.config = this.getComponentConfig(
      ADDED_TO_CART_MODAL_COMPONENT_KEY,
      { ...ADDED_TO_CART_MODAL_COMPONENT_CONFIG_MAP }
    )

    if (this.shouldFetchSuggested) {
      this.handleSuggestedProductsLoad(
        this.payload.product.sku,
        SuggestedProductsSource.AddToCartModal,
        RecommendationAction.BrandAndCategory,
        this.loadSuggestedProductsByDelivery
      )
    } else {
      this.loadSuggestedProducts(this.payload.product.bundledSiblings)
    }
  },
  mounted () {
    this.initDynamicEventsData()
  }
})
export class AddedToCartModal extends Mixins<
  AbstractModal<AddedToCartModalPayload>,
  StructureConfigurable,
  SuggestedProductsMixin,
  DynamicHelperMixin>(
    AbstractModal,
    StructureConfigurable,
    SuggestedProductsMixin,
    DynamicHelperMixin
  ) {
  protected config!: AddedToCartModalConfig
  public suggestedProductsRef = null

  @Watch('suggestedProductsRef')
  public onSuggestedProductsRefsUpdate (ref: HTMLDivElement | null) {
    this.handleContentShow(ref, false)
  }

  /**
   * Determines whether to fetch the suggested products.
   */
  public get shouldFetchSuggested (): boolean {
    return !Array.isArray(this.payload.product.bundledSiblings) || this.payload.product.bundledSiblings.length === 0
  }

  public get component (): VueConstructor | AsyncComponent {
    return addedToCartModalComponentRegistry[this.getConfigProperty<string>('layout')]
  }

  public get loadSuggestedProductsByDelivery (): boolean {
    return this.getConfigProperty('loadSuggestedProductsByDelivery')
  }

  public get shouldSendDynamicContentEvents (): boolean {
    return this.getConfigProperty('shouldSendDynamicContentEvents')
  }

  /**
   * Composes a content for dynamic data events
   * @see DynamicContent
   */
  public initDynamicEventsData (): void {
    if (!this.shouldSendDynamicContentEvents) {
      return
    }

    this.composeDynamicContentData(
      this.$t('front.products.organisms.addedToCartModal.suggestedProductsLabel').toString(),
      SuggestedProductsSource.AddToCartModal
    )
  }
}

export default AddedToCartModal
